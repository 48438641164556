.brand {
	height: 3rem;
	margin-right: 1rem;
}
.brand img {
	margin-right: 0.5rem;
	height: 100%;
	margin-bottom: 0.5rem;
}
.brand span {
	font-size: 1.2em;
}

.account {
	margin-left: auto;
	display: flex;
	align-items: center;
}


.verticalMenu {
	text-align: center;
}
.verticalMenu .brand {
	margin-right: 0;
	margin-bottom: 1rem;
}
.verticalMenu .account {
	flex-direction: column;
	margin-top: 2rem;
}
.verticalMenu .account > div {
	margin: 1rem;
}