.dimmer {
	position: absolute;
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: #121212e1;
}

.loadingDimmer {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 2;
	background: #11111177;
	display: flex;
	align-items: center;
	justify-content: center;
}