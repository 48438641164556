@import '~antd/dist/antd.dark.less';

@primary-color: #00bc8c;
@secondary-color: #7289da;
@link-color: #00bc8c;
@black: #202225;
@grey-1: #292b2f;
@grey-2: #2f3136;
@grey-25: #34373b;
@grey-3: #36393f;
@grey-4: #6c717a;
@white: #dcddde;
@white-1: #fff;

@body-background: @grey-3;
@component-background: @grey-2;
@layout-body-background: @grey-3;
@input-bg: @grey-2;
@border-color-base: lighten(@grey-3, 10%);

@text-color: lighten(@white, 10%);
@heading-color: @white;
@text-color-secondary: darken(@white, 7%);

@warning-color: @primary-color;

:root {
	--color-primary: @primary-color;
	--color-secondary: @secondary-color;

	--color-black: @black;
	--color-grey-1: @grey-1;
	--color-grey-2: @grey-2;
	--color-grey-25: @grey-25;
	--color-grey-3: @grey-3;
	--color-grey-4: @grey-4;
	--color-white: @white;
	--color-white-1: @white-1;
}

body {
	background: var(--color-grey-3);
}

img {
	max-width: 100%;
	max-height: 100%;
}
.music-progress&.ant-progress-show-info .ant-progress-outer {
	margin-right: calc(-8em - 8px);
	padding-right: calc(8em + 8px);
}
.music-progress .ant-progress-text {
	width: 8em;
}
.wrap-button {
	white-space: normal !important;
	height: auto !important;
}

.vertical-alert {
	flex-direction: column !important;
	padding: 15px !important;
}
.vertical-alert > .ant-alert-action {
	margin-left: 0 !important;
}