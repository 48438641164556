.whyMizar {
	font-size: 1.4em;
	line-height: 1.4em;
}
.whyMizar em {
	color: var(--color-primary);
	font-style: normal;
	font-size: 1.1em;
	font-weight: bold;
}
.whyMizar blockquote {
	color: var(--color-white);
}

.quotes {
	display: flex;
	align-items: flex-start;
}
.quotes > div {
	font-size: 0.9em;
	width: 12rem;
	
	flex-grow: 1;
	
	border-left: 0.3rem solid var(--color-primary);
	margin: 1rem;
	padding-left: 0.6rem;
}
.quotes span {
	font-size: 0.9em;
	color: #ccc;
	float: right;
}
.quotesSmall {
	flex-direction: column;
	align-items: stretch;
}
.quotesSmall > div {
	width: auto;
	margin: 1rem 0.5rem;
}