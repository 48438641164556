.leaderboard-table tr {
	transition: 1s
}
.leaderboard-table .ant-table-cell {
	border-top: 0;
	background: linear-gradient(to bottom, #292b2f 96%, #00000000 96%) !important;
}
.leaderboard-table tr[alternate="true"] .ant-table-cell {
	border-top: 0;
	background: linear-gradient(to bottom, #25272a 96%, #00000000 96%) !important;
}