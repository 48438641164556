.guildMember {
	margin-top: 1rem;
	padding: 1.5rem;
	width: 20rem;

	display: flex;
	align-items: center;
	text-align: right;
	flex-wrap: wrap;
	flex-grow: 1;

	background: var(--color-grey-2);
}